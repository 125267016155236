@import url('https://fonts.googleapis.com/css?family=Rubik');

.font-custom {
  font-family: 'Rubik', sans-serif;
}

*{
    margin: 0;
    padding: 0;
  }


@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.active {
  position: relative;
}

.active::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0; /* Set initial width to 0 */
  height: 3px;
  background-color: #6ADB84;
  border-radius: 1px;
  transition: width 0.3s ease; /* Add a transition for the width property */
}

.active:hover::after {
  width: 100%; /* Expand to 100% on hover for a visual indication */
}

.active::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0%;
  width: 26px; /* Set the width to 50% of the line */
  height: 3px;
  background-color: #6ADB84;
  border-radius: 1px;
  transition: width 0.7s ease; /* Add a transition for the width property */
}


.actives {
  position: relative;
}

.actives::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0; /* Set initial width to 0 */
  height: 2px;
  background-color: #F18934;
  border-radius: 1px;
  transition: width 0.3s ease; /* Add a transition for the width property */
}

.actives:hover::after {
  width: 100%; /* Expand to 100% on hover for a visual indication */
}

.actives::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0%;
  width: 26px; /* Set the width to 50% of the line */
  height: 2px;
  background-color: #F18934;
  border-radius: 1px;
  transition: width 0.7s ease; /* Add a transition for the width property */
}

.carousel-item {
  padding: 4px; 
}
